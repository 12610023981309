import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Header from "./header";
import ShareBar from "./share-bar";
// import ReactDisqusComments from "react-disqus-comments";
import styles from '../styles/blog-post.module.css';

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <>
      <Header />
      <div className={[styles.flexAlign, 'fade-down'].join(' ')}>
        <div className={styles.main}>
          <section>
            <h1>
              {post.frontmatter.title}
            </h1>
            <p>
            {post.frontmatter.description}
            </p>
          </section>
          <section className={[styles.postDetails, styles.textMuted].join(' ')}>
            <Img className={[styles.avatar, styles.alignMeta].join(' ')} fixed={post.frontmatter.authorAvatar.childImageSharp.fixed}/>
            <div style={{paddingTop: "7px"}}>
              <span>
                {post.frontmatter.authorName}
              </span>
              <div style={{marginTop: "3px"}}>
                <span className="middot-divider">{post.frontmatter.dateModified}</span>
                <span>{`${post.timeToRead} min read`}</span>
              </div>
            </div>
            <div>

            </div>
          </section>
        </div>
        <div className={styles.imageContent}>
          <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
        </div>
        <div className={styles.main}>
          <div className="sticky-10px">
            <ShareBar title={post.frontmatter.title}/>
          </div>
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </div>
      </div>
      {/* <div className={styles.disqus}>
        <ReactDisqusComments
          shortname="example"
          identifier="something-unique-12345"
          title="Example Thread"
          url="http://www.example.com/example-thread"
          category_id="123456"
        />
      </div> */}
    </>
  )
}

export const query = graphql`
  query($pageSlug: String!) {
    markdownRemark(fields: { slug: { eq: $pageSlug } }) {
      html
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 920) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        authorAvatar {
          childImageSharp {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        authorName
        dateModified(formatString: "MMM DD'YY")
      }
      timeToRead
    }
  }
`