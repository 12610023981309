import React from 'react';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share'

export default (props) => {
 return(
     <div className="share-align">
        <FacebookShareButton url='www.google.com' className="zero-outline" quote={props.title}>
            <FacebookIcon size={32} iconBgStyle={{fill: "rgba(0,0,0,.68)"}} />
        </FacebookShareButton>
        <TwitterShareButton url='www.google.com' className="zero-outline" title={props.title}>
            <TwitterIcon size={32} iconBgStyle={{fill: "rgba(0,0,0,.68)"}} />
        </TwitterShareButton>
        <RedditShareButton url='www.google.com' className="zero-outline" title={props.title} windowWidth={660} windowHeight={460}>
            <RedditIcon size={32} iconBgStyle={{fill: "rgba(0,0,0,.68)"}} />
        </RedditShareButton>
     </div>
 )
}